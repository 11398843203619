import * as React from "react"
import { Container, Row, Col, Form  } from "react-bootstrap"
import {Link}  from "gatsby"
import {Helmet} from "react-helmet";
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import SimilarProperty from "../components/PropertyDetails/Property/DetailsErrorProperties";

import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL } from "../components/common/site/constants"

const DetailsNotFoundPage = (props) => (
  <div className="wrapper error-details property-details-error-page">
  <Header/>
    <Seo title="404: Not found" />
      <div className="error-page">
        <Container>
          <Row>
            <Col lg={12} className="main-banner content">
              <h2>Sorry, we can't find that property</h2>
              <p>Unfortunately we couldn't find the property you tried to view. If you used a bookmark to come here, we're sorry but that property is no longer on the market. Otherwise, double check the number in the link you entered - it should be eight digits long.</p>
              <p>Make sure you don't miss out on properties again - receive updates straight to your computer or phone</p>
              <div className="btn-wrap">
                <Link class="btn btn-secondary" to={VALUATION_PAGE_URL.alias}>
                  <span>Selling or letting?</span>Book a valuation
                </Link>
                <Link class="btn" to={FIND_A_PROPERTY_PAGE_URL.alias}>
                  <span>Buying or renting?</span>Our live properties
                </Link>
              </div>
              <SimilarProperty />
            </Col>
          </Row>
        </Container>
      </div>
    <Footer />

  </div>
)

export default DetailsNotFoundPage
